import React from 'react';
import './style.scss';

function PageFooter({ author, githubUrl }) {
  return (
    <footer className="page-footer-wrapper">
      <p className="page-footer">© Tmax WAPL {new Date().getFullYear()}&nbsp;</p>
    </footer>
  );
}

export default PageFooter;
